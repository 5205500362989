<messages>["../Registrar"]</messages>

<template>
  <v-text-field
    v-model.trim="pattern"
    name="pattern"
    :label="$t ('label.pattern')">
    <template #append-outer>
      <base-dialog
        v-model="dialog"
        scrollable
        mw0>
        <template #activator="{ on: dia }">
          <v-tooltip top>
            <template #activator="{ on: tooltip }">
              <v-icon v-on="{...dia, ...tooltip}">
                help
              </v-icon>
            </template>
            {{ $t ('label.click') }}
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title>
            <div
              v-t="'label.patternInfo.title'"
              class="text-h5"/>
          </v-card-title>
          <v-card-text>
            <p>{{ $t ('label.patternInfo.info') }}</p>
            <ul>
              <li v-for="i in placeholders" :key="i">
                {{ $t(i) }}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.close'" text
              @click="dialog = flase"/>
          </v-card-actions>
        </v-card>
      </base-dialog>
    </template>
  </v-text-field>
</template>

<script>
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {

    components: {
      BaseDialog
    },

    props: {
      value: {
        type: String,
        default: ''
      }
    },

    data () {
      return {
        dialog: false
      }
    },

    computed: {
      pattern: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },
      placeholders () {
        return [
          '0', '1', '2'
        ].map ((i) => `label.patternInfo.${i}`)
      }
    }
  }
</script>
