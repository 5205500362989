<messages>["../Registrar"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <!-- "registrar does not exist" alert -->
    <v-alert
      v-if="!isLoading && !isCreate && !registrar.name"
      type="error">
      {{ $t ('invalidRegistrarId', { id: registrar.id }) }}
    </v-alert>

    <v-card v-else>
      <form
        novalidate
        @submit.prevent="processRegistrarData">
        <v-card-title primary-title>
          <div>
            <div
              v-t="`registrar.title.${operationName}`"
              class="text-h5"/>
            <div
              v-t="`subtitle.${operationName}`"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.name"
                name="name"
                spellcheck="false"
                class="required"
                :disabled="!isCreate"
                :label="$t ('label.name')"
                :error-messages="validationErrors(
                  'registrar.name',
                  {
                    required: 'required.name',
                    registrarName: 'invalid.registrarName'
                  })"
                @blur="$v.registrar.name.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.displayName"
                name="displayName"
                class="required"
                :label="$t ('label.displayName')"
                :error-messages="validationErrors(
                  'registrar.displayName',
                  {
                    required: 'required.displayName'
                  })"
                @blur="$v.registrar.displayName.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.number="registrar.ianaId"
                name="ianaId"
                :label="$t ('label.ianaId')"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.webUrl"
                name="webUrl"
                class="required"
                :label="$t ('label.webUrl')"
                :error-messages="validationErrors(
                  'registrar.webUrl',
                  {
                    required: 'required.webUrl',
                    url: 'general.invalid.url'
                  })"
                @blur="$v.registrar.webUrl.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.whoisUrl"
                name="whoisUrl"
                class="required"
                :label="$t ('label.whoisUrl')"
                :error-messages="validationErrors(
                  'registrar.whoisUrl',
                  {
                    required: 'required.whoisUrl',
                    url: 'general.invalid.url'
                  })"
                @blur="$v.registrar.whoisUrl.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.whoisDomain"
                name="whoisDomain"
                class="required"
                :label="$t ('label.whoisDomain')"
                :error-messages="validationErrors(
                  'registrar.whoisDomain',
                  {
                    required: 'required.whoisDomain'
                  })"
                @blur="$v.registrar.whoisDomain.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.abuseEmail"
                spellcheck="false"
                class="required"
                :label="$t ('label.abuseEmail')"
                :error-messages="validationErrors(
                  'registrar.abuseEmail',
                  {
                    required: 'required.abuseEmail',
                    email: 'general.invalid.email'
                  })"
                @blur="$v.registrar.abuseEmail.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.abusePhone"
                name="abusePhone"
                class="required"
                :label="$t ('label.abusePhone')"
                :error-messages="validationErrors(
                  'registrar.abusePhone',
                  {
                    required: 'required.abusePhone',
                    phone: 'general.invalid.phone'
                  })"
                @blur="$v.registrar.abusePhone.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="registrar.rdapUrl"
                name="rdapUrl"
                class="required"
                :label="$t ('label.rdapUrl')"
                :error-messages="validationErrors(
                  'registrar.rdapUrl',
                  {
                    required: 'required.rdapUrl',
                    url: 'general.invalid.url'
                  })"
                @blur="$v.registrar.rdapUrl.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <pattern-input v-model.trim="registrar.pattern"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <floating-button
            color="primary"
            :l-offset="2"
            :b-offset="2">
            {{ $t(`general.button.${operationName}`) }}
          </floating-button>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            @click="onCancel"/>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapGetters} from 'vuex'

  import {required, url} from 'vuelidate/lib/validators'
  import {email, phone, registrarName} from '@/app/validators'

  import FloatingButton from '@/app/core/components/FloatingButton'

  import ContentHeightReporter from '@/app/core/mixins/ContentHeightReporter'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import PatternInput from './PatternInput'

  import _cloneDeep from 'lodash/cloneDeep'
  import _isEqual from 'lodash/isEqual'
  import _pick from 'lodash/pick'

  export const createEmptyRegistrar = () => ({
    id: undefined,
    name: undefined,
    displayName: undefined,
    ianaId: undefined,
    webUrl: undefined,
    whoisUrl: undefined,
    whoisDomain: undefined,
    abuseEmail: undefined,
    abusePhone: undefined,
    rdapUrl: undefined,
    pattern: undefined
  })

  export default {
    name: 'RegistrarCreateUpdate',

    components: {
      FloatingButton,
      PatternInput
    },

    mixins: [ContentHeightReporter, validationMixins],

    props: {
      registrarData: {
        type: Object,
        default: createEmptyRegistrar
      },
      isCreate: Boolean
    },

    data: () => ({
      defaultRegistrar: null,
      isLoading: true,
      registrar: {}
    }),

    validations: {
      registrar: {
        name: {required, registrarName},
        displayName: {required},
        webUrl: {required, url},
        whoisUrl: {required, url},
        whoisDomain: {required},
        abuseEmail: {required, email},
        abusePhone: {required, phone},
        rdapUrl: {required, url}
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayManageAllEntities'
      ]),

      operationName () {
        return this.isCreate ? 'create' : 'update'
      }
    },

    watch: {
      registrar: {
        handler (newValue) {
          if (this.defaultRegistrar &&
            !_isEqual (newValue, this.defaultRegistrar)) {
            this.$emit ('unsavedChanges', true)
          } else {
            this.$emit ('unsavedChanges', false)
          }
        },
        deep: true
      },

      registrarData: {
        handler (newData) {
          this.populateRegistrarObject (newData)
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData',
        clearRegistrars: 'cache/clearRegistrars'
      }),

      // populate this component's registrar with the values from the dataObject
      populateRegistrarObject (dataObject) {
        this.registrar = createEmptyRegistrar ()
        Object.assign (this.registrar, dataObject)

        if (this.registrar.id) {
          this.loadRegistrar (this.registrar.id)
        }

        this.$v.$reset ()
      },

      loadRegistrar (registrarId) {
        this.isLoading = true

        this.fetchData ({
          op: 'registrar/load',
          params: {id: registrarId},
          cb: data => {
            this.registrar = data.registrarData

            this.defaultRegistrar = _cloneDeep (this.registrar)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      // store or update the registrar
      processRegistrarData () {
        if (!this.$v.$invalid) {
          const registrarData =
            _pick (this.registrar, Object.keys (createEmptyRegistrar ()))

          this.fetchData ({
            op: `registrar/${this.isCreate ? 'create' : 'update'}`,
            params: {registrarData},
            cb: (data) => {
              this.$emit ('unsavedChanges', false)
              this.$emit ('success', {...registrarData, id: data.id})
              this.clearRegistrars ()
              if (this.isCreate) {
                this.registrar = {}
                this.$v.$reset ()
              }
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      onCancel () {
        this.$emit ('unsavedChanges', false)
        this.$emit ('cancel')
      }
    }

  }
</script>
