var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoading && !_vm.isCreate && !_vm.registrar.name)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t ('invalidRegistrarId', { id: _vm.registrar.id }))+" ")]):_c('v-card',[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.processRegistrarData($event)}}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:(("registrar.title." + _vm.operationName)),expression:"`registrar.title.${operationName}`"}],staticClass:"text-h5"}),_c('div',{directives:[{name:"t",rawName:"v-t",value:(("subtitle." + _vm.operationName)),expression:"`subtitle.${operationName}`"}],staticClass:"cgwng-subheading"})])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"name","spellcheck":"false","disabled":!_vm.isCreate,"label":_vm.$t ('label.name'),"error-messages":_vm.validationErrors(
                'registrar.name',
                {
                  required: 'required.name',
                  registrarName: 'invalid.registrarName'
                })},on:{"blur":_vm.$v.registrar.name.$touch},model:{value:(_vm.registrar.name),callback:function ($$v) {_vm.$set(_vm.registrar, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"displayName","label":_vm.$t ('label.displayName'),"error-messages":_vm.validationErrors(
                'registrar.displayName',
                {
                  required: 'required.displayName'
                })},on:{"blur":_vm.$v.registrar.displayName.$touch},model:{value:(_vm.registrar.displayName),callback:function ($$v) {_vm.$set(_vm.registrar, "displayName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.displayName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"ianaId","label":_vm.$t ('label.ianaId')},model:{value:(_vm.registrar.ianaId),callback:function ($$v) {_vm.$set(_vm.registrar, "ianaId", _vm._n($$v))},expression:"registrar.ianaId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"webUrl","label":_vm.$t ('label.webUrl'),"error-messages":_vm.validationErrors(
                'registrar.webUrl',
                {
                  required: 'required.webUrl',
                  url: 'general.invalid.url'
                })},on:{"blur":_vm.$v.registrar.webUrl.$touch},model:{value:(_vm.registrar.webUrl),callback:function ($$v) {_vm.$set(_vm.registrar, "webUrl", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.webUrl"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"whoisUrl","label":_vm.$t ('label.whoisUrl'),"error-messages":_vm.validationErrors(
                'registrar.whoisUrl',
                {
                  required: 'required.whoisUrl',
                  url: 'general.invalid.url'
                })},on:{"blur":_vm.$v.registrar.whoisUrl.$touch},model:{value:(_vm.registrar.whoisUrl),callback:function ($$v) {_vm.$set(_vm.registrar, "whoisUrl", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.whoisUrl"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"whoisDomain","label":_vm.$t ('label.whoisDomain'),"error-messages":_vm.validationErrors(
                'registrar.whoisDomain',
                {
                  required: 'required.whoisDomain'
                })},on:{"blur":_vm.$v.registrar.whoisDomain.$touch},model:{value:(_vm.registrar.whoisDomain),callback:function ($$v) {_vm.$set(_vm.registrar, "whoisDomain", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.whoisDomain"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"spellcheck":"false","label":_vm.$t ('label.abuseEmail'),"error-messages":_vm.validationErrors(
                'registrar.abuseEmail',
                {
                  required: 'required.abuseEmail',
                  email: 'general.invalid.email'
                })},on:{"blur":_vm.$v.registrar.abuseEmail.$touch},model:{value:(_vm.registrar.abuseEmail),callback:function ($$v) {_vm.$set(_vm.registrar, "abuseEmail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.abuseEmail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"abusePhone","label":_vm.$t ('label.abusePhone'),"error-messages":_vm.validationErrors(
                'registrar.abusePhone',
                {
                  required: 'required.abusePhone',
                  phone: 'general.invalid.phone'
                })},on:{"blur":_vm.$v.registrar.abusePhone.$touch},model:{value:(_vm.registrar.abusePhone),callback:function ($$v) {_vm.$set(_vm.registrar, "abusePhone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.abusePhone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"name":"rdapUrl","label":_vm.$t ('label.rdapUrl'),"error-messages":_vm.validationErrors(
                'registrar.rdapUrl',
                {
                  required: 'required.rdapUrl',
                  url: 'general.invalid.url'
                })},on:{"blur":_vm.$v.registrar.rdapUrl.$touch},model:{value:(_vm.registrar.rdapUrl),callback:function ($$v) {_vm.$set(_vm.registrar, "rdapUrl", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.rdapUrl"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pattern-input',{model:{value:(_vm.registrar.pattern),callback:function ($$v) {_vm.$set(_vm.registrar, "pattern", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registrar.pattern"}})],1)],1)],1),_c('v-card-actions',[_c('floating-button',{attrs:{"color":"primary","l-offset":2,"b-offset":2}},[_vm._v(" "+_vm._s(_vm.$t(("general.button." + _vm.operationName)))+" ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.cancel'),expression:"'general.button.cancel'"}],attrs:{"text":""},on:{"click":_vm.onCancel}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }