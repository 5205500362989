<messages>["./Registrar"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <v-col v-if="isSuccessAlertVisible" cols="12">
      <v-alert
        v-model="isSuccessAlertVisible"
        type="success"
        dismissible>
        <i18n path="createUpdate.success">
          <template #link>
            <router-link
              class="success-alert-link"
              :to="{ name: 'registrar.view', params: { id: successData.id} }"
              v-text="successData.name"/>
          </template>
        </i18n>
      </v-alert>
    </v-col>
    <v-col cols="12">
      <registrar-create-update :is-create="true" @cancel="back" @success="displaySuccess"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'
  import RegistrarCreateUpdate from './components/RegistrarCreateUpdate'
  import {mapMutations} from 'vuex'

  export default {
    name: 'RegistrarCreate',

    components: {
      BaseLayout,
      RegistrarCreateUpdate
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        successData: null
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      back () {
        this.$router.back ()
      },

      displaySuccess (data) {
        this.successData = data
        this.addEvent ({
          type: 'success',
          message: this.$t ('createUpdate.success', {link: data.name}),
          objects: [{
            name: data.name,
            link: {
              name: 'registrar.view',
              params: {id: data.id}
            }
          }]
        })
        this.isSuccessAlertVisible = true
      }
    }
  }
</script>
